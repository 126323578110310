<template>
    <el-row>
        <el-col :span="14" :offset="5">
            <div v-loading="$wait.is('loading')" class="card p-10" element-loading-spinner="el-custom-spinner">
                <div class="flex">
                    <div class="left flex-1">
                        <p class="font-semibold">
                            {{ salaryData.employee_name }} {{ salaryData.employee_surname }}
                        </p>
                        <p class="mt-4">
                            {{ salaryData.employee_street }}
                        </p>
                        <p>
                            {{ salaryData.employee_post_code }} {{ salaryData.employee_city }}
                        </p>
                        <!-- <p>
                            Status: {{ salaryData.status }}
                        </p> -->
                        <p class="mt-4">
                            Personnummer: {{ salaryData.employee_personnummer }}
                        </p>
                        <!-- <p>
                            {{ salaryData.calculation_type }}
                        </p> -->
                    </div>
                    <div class="right flex-1 text-right">
                        <p>
                            {{ $t('salaries.period') }}: {{ salaryData.period }}
                        </p>
                    </div>
                </div>
                <div />
                <!-- uuid
                calculation_type
                created_at
                employee_uuid -->
                <el-table v-if="salaryData.items.length" :data="salaryData.items" class="w-full mt-6">
                    <el-table-column :label="$t('salaries.date')" prop="name" />
                    <el-table-column :label="$t('salaries.bid_per_hour')" width="120" align="center">
                        <template slot-scope="scope">
                            {{ intToMoneyFormat(scope.row.bid_per_hour) }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('salaries.worked_time')" width="120">
                        <template slot-scope="scope">
                            {{ normalizeTime(scope.row.worked_minutes) }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    data() {
        return {
            salaryUuid: this.$route.params.uuid,
            salaryData: {
                items: [],
            },
        };
    },

    computed: {
        employeeNameSurname() {
            if (!this.salaryData) return '';
            return `${this.salaryData.employee_name} ${this.salaryData.employee_surname}`;
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('salaries.salary'));
        this.$store.commit('setActiveMenuItem', '6-1');

        this.$wait.start('loading');
        this.getDetails();
    },

    methods: {
        async getDetails() {
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/salaries/${this.salaryUuid}`);
                this.salaryData = data;
                const employee = `${data.employee_name} ${data.employee_surname}`;
                this.$store.commit('setPageTitle', `${this.$t('salaries.salary')} - ${employee}`);
                this.$wait.end('loading');
            } catch (error) {
                this.$wait.end('loading');
            }
        },
    },
};
</script>
